import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, DataTable, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock } from '../../../components';
import { StaticImage } from 'gatsby-plugin-image';
import bladderSafetyTable from "../../../assets/bla-057-safety-small-V2.svg"
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-057: Selected Safety Data and Adverse Reactions`,
    keywords: `keytruda high-risk nmibc adverse reactions, keynote-057 safety data`,
    description: `Learn about adverse reactions to KEYTRUDA® (pembrolizumab) in certain patients with high-risk non-muscle invasive bladder cancer (NMIBC). See safety data from KEYNOTE-057.`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/nmibc/","@type":"ApprovedIndication","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA is indicated for the treatment of patients with Bacillus Calmette-Guerin (BCG)-unresponsive, high-risk, non-muscle invasive bladder cancer (NMIBC) with carcinoma in situ (CIS) with or without papillary tumors who are ineligible for or have elected not to undergo cystectomy."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/nmibc/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

// image alts - so they do not repeat in mobile & desktop
const nmibcSafetyAlt = "Safety Data From KEYNOTE-057"

//from /src/util/data/indications.js
const indicationId = 17;
const jobCode = jobCodes[17].jobCode;


const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
                ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    {label: 'Incidence of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Management of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Safety Data from KEYNOTE&#8288;-&#8288;057'}
]

const NMIBCsafetyFootnotes = [
    {
        label: 'c.',
        text: 'Graded per National Cancer Institute - Common Terminology Criteria for Adverse Events (NCI-CTCAE) v4.03.'
    },
    {
        label: 'd.',
        text: 'Includes asthenia, fatigue, malaise.'
    },
    {
        label: 'e.',
        text: 'Includes edema peripheral, peripheral swelling.'
    },
    {
        label: 'f.',
        text: 'Includes diarrhea, gastroenteritis, colitis.'
    },
    {
        label: 'g.',
        text: 'Includes rash maculopapular, rash, rash erythematous, rash pruritic, rash pustular, erythema, eczema, eczema asteatotic, lichenoid keratosis, urticaria, dermatitis.'
    },
    {
        label: 'h.',
        text: 'Includes back pain, myalgia, musculoskeletal pain, pain in extremity, musculoskeletal chest pain, neck pain.'
    },
    {
        label: 'i.',
        text: 'Includes cough, productive cough.'
    },
]

const NMIBCsafetyDefinitions ="BCG = Bacillus Calmette-Guerin; CIS = carcinoma in situ."

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label}>
                            <TextBlock>
                                <p>The safety of KEYTRUDA was investigated in the KEYNOTE&#8288;-&#8288;057 trial that enrolled 148 patients with high-risk NMIBC, 96 of whom had BCG-unresponsive CIS with or without papillary tumors.</p>
                                <p>The median duration of exposure to KEYTRUDA was 4.3 months (range: 1 day–25.6 months).</p>
                            </TextBlock>
                            <DataTable 
                                title="Adverse Reactions Occurring in ≥10% of Patients Receiving KEYTRUDA"
                                footnotes={NMIBCsafetyFootnotes} 
                                definitions={NMIBCsafetyDefinitions} 
                                additionalInformation={[
                                    {
                                        title: 'Additional Information',
                                        text: `<p>KEYTRUDA was discontinued due to adverse reactions in 11% of patients. The most common adverse reaction (>1%) resulting in permanent discontinuation of KEYTRUDA was pneumonitis (1.4%).</p>
                                               <p>Adverse reactions leading to interruption of KEYTRUDA occurred in 22% of patients; the most common (≥2%) were diarrhea (4%) and urinary tract infection (2%). Serious adverse reactions occurred in 28% of patients treated with KEYTRUDA. The most frequent serious adverse reactions (≥2%) in patients treated with KEYTRUDA were pneumonia (3%), cardiac ischemia (2%), colitis (2%), pulmonary embolism (2%), sepsis (2%), and urinary tract infection (2%).</p>
                                            `
                                    },
                                ]}  
                                mobileImage={() => <img src={bladderSafetyTable} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={nmibcSafetyAlt}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/bla-057-safety-large-V2.png" alt={nmibcSafetyAlt}/>}
                                />
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}

                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
